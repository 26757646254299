@import "~antd/dist/antd.less";

@font-family: "Roboto", sans-serif;
@padding-lg: 30px;
@padding-md: 24px;
@border-radius-base: 5px;
@primary-color: #00C1D5;
@info-color: #20063b;
@success-color: #00C1D5;
@warning-color: #FE8833;
@error-color: #ff3341;

* {
  font-family: 'Poppins', sans-serif;
}

.ant-form label {
  font-size: inherit;
}

.ant-form-item {
  margin: 0;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.react-tel-input .country-list {
  box-shadow: 1px 2px 10px rgb(0 0 0 / 4%) !important;
  border: 1px solid #d3d3d35e !important;
  border-radius: 8px !important;
}

.react-tel-input .country-list .search-box {
  width: calc(100% - 9px) !important;
  font-size: 13px !important;
  padding: 8px 12px !important;
  border: 1px solid #ededed !important;
  border-radius: 8px !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.react-tel-input .country-list .search-emoji {
  display: none !important;
}

.react-tel-input .form-control[disabled] {
  background: whitesmoke !important;
}

.modal-upload {
  .ant-modal-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 149, 153, 0.1);
    border-radius: 24px !important;
    width: 600px;
  }

  .ant-modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #22313F;
    text-align: center;
    padding-top: 15px;
  }

  .ant-modal-header {
    border-bottom-width: 0;
    border-radius: 24px;
  }

  .ant-upload.ant-upload-drag {
    border: 1px dashed #55BED2;
    border-radius: 24px;
    background-color: #fff;
  }

  .ant-modal-body {
    padding: 0 44px 32px 44px;
  }

  .ant-modal-footer {
    border-top-width: 0;
  }

  .ant-btn.ant-btn-primary {
    width: 194px;
    height: 64px;
    background: #55BED2;
    border: 1px solid #55BED2;
    border-radius: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }

  .ant-btn.ant-btn-default {
    display: none;
  }

  .ant-modal-footer {
    text-align: center;
    padding-bottom: 24px;
  }

  .ant-upload-btn {
    padding-top: 50px !important;
  }

  .close-button {
    position: absolute;
    top: -22px;
    right: -20px;
    z-index: 10;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-input {
  padding-right: 15px !important;
}

input::placeholder {
  color: #d4d4d4 !important;
}

.ant-tooltip-inner {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  width: 200px;
}

@media (max-width: 600px) {
  #root {
    height: 100%;
  }
  .hidden-mobile {
    display: none !important;
  }

  .space-mobile-2 {
    width: 1px;
    height: 16px;
  }

  input::placeholder {
    font: 15px 'Poppins', sans-serif !important;
  }

  .ant-col.ant-col-11.phone-mobile {
    flex: 1 !important;
    flex-basis: 100% !important;
  }

  input {
    font-size: 16px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .modal-upload .ant-modal-content {
    width: 100% !important;
  }

  .modal-upload .ant-modal-content {
    width: 100% !important;
  }

  .modal-upload .ant-modal-header {
    padding: 12px 16px;
  }

  .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 12px !important;
    padding-top: 18px !important;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon img {
    width: auto;
    height: 65px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 12px;
  }


  .ant-upload-draggable-list-item .ant-space .ant-space-item:nth-child(2) > span {
    font-size: 14px;
    line-height: 18px;
    display: block;
    margin-bottom: -5px;
  }

  .modal-upload .ant-btn.ant-btn-primary {
    height: 44px;
    width: 150px;
  }

  .modal-upload .close-button {
    right: -12px;
    top: -32px;
  }

  .view-submited {
    padding-top: 12px;
  }

  .view-submited .ant-col {
    width: 100%;
    max-width: 100%;
    margin-bottom: 4px;
  }

  .view-submited .ant-col div {
    font-size: 14px !important;
  }

  .view-submited .ant-col.ant-col-7 {
    flex-basis: 100%;
    margin-bottom: 16px;
  }

  .view-submited .space-view {
    height: 0 !important;
  }

  .view-submited .ant-col-1 {
    margin: 0;
    height: 0;
  }

  .view-submited {
    margin-top: 19px;
  }

  .view-submited .ant-col.ant-col-7 > div > span {
    height: 20px;
    line-height: 20px !important;
    display: inline-block;
    margin-left: 6px;
  }

  .ant-upload-draggable-list-item .ant-space .ant-space-item:last-child {
    width: 100%;
    overflow: hidden;

    .f-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  input.ant-input.ant-input-disabled {
    border-color: rgb(233, 238, 246);
  }

  .react-tel-input :disabled {
    opacity: 1;
  }
}

.ant-btn.ant-btn-primary[disabled] {
  background: #d6d6d6;
  border-color: #d6d6d6;
}

.react-tel-input .form-control:focus, .react-tel-input .form-control:hover {
  border: 1px solid #55BED2 !important;
  box-shadow: 0 0 0 2px rgb(85 190 210 / 20%) !important;
}
